import React, { useState, useEffect } from "react";
import {
  Button,
  CardBody,
  Switch,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import ErrorBoundary from '../ErrorBoundary';

const batchNumberStyles = `
  .batch-number-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .batch-number-item {
    background-color: #f3f4f6;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9em;
    color: #1f2937;
    white-space: nowrap;
  }

  .batch-number-cell {
    padding: 8px !important;
    vertical-align: top !important;
    background-color: #fafafa !important;
  }
`;

// Add this new component for the modal
const BatchDetailsModal = ({ isOpen, onClose, batchDetails }) => {
  return (
    <Dialog open={isOpen} handler={onClose} size="sm">
      <DialogHeader>Batch Details</DialogHeader>
      <DialogBody>
        <div className="space-y-4">
          {batchDetails.map((batch, index) => (
            <div key={index} className="p-3 bg-gray-50 rounded-lg">
              <div className="font-mono">
                Batch Number: {batch.batch_number}
              </div>
              <div className="text-gray-600">
                Quantity: {batch.quantity}
              </div>
            </div>
          ))}
        </div>
      </DialogBody>
    </Dialog>
  );
};

// Move formatBatchNumber outside of ProcessTables component, after BatchDetailsModal
const formatBatchNumber = (batchNumber, onViewDetails) => {
  if (!batchNumber) return "-";
  if (typeof batchNumber === 'string' && !batchNumber.trim()) return "-";
  
  try {
    if (Array.isArray(batchNumber)) {
      return (
        <Button 
          size="sm"
          variant="outlined"
          className="py-1 px-3 text-xs"
          onClick={() => onViewDetails(batchNumber)}
        >
          View {batchNumber.length} Items
        </Button>
      );
    }

    // Handle string values
    return (
      <div className="batch-number-container">
        <div className="batch-number-item">
          {batchNumber}
        </div>
      </div>
    );

  } catch (error) {
    console.error("Error in formatBatchNumber:", error, batchNumber);
    return "-";
  }
};

export const ProcessTables = ({
  addRow,
  jsonData,
  editIndex,
  activeTab,
  setActiveTab,
  setEditIndex,
  selectedRows,
  toggleRowSelection,
  handleInputChange,
  isEditingEnabled,
  setIsEditingEnabled,
  deleteSelectedRows,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [editFieldValue, setEditFieldValue] = useState("");

  const handleFieldChange = (e) => {
    setEditFieldValue(e.target.value);
  };

  const handleFieldEdit = (key, value) => {
    if (!isEditingEnabled) return;
    setEditFieldKey(key);
    setEditFieldValue(value !== null && value !== undefined ? value : "");
  };

  const saveFieldEdit = (key) => {
    jsonData[key] = editFieldValue;
    setEditFieldKey(null);
  };

  return (
    <>
      <CardBody>
        <Typography variant="h6" color="black">
          Invoice Data :-
        </Typography>
        <div className="my-4">
          <Switch
            checked={isEditingEnabled}
            onChange={() => setIsEditingEnabled(!isEditingEnabled)}
            label="Enable / Disable Editing Zoho Data"
            className="mb-4"
          />
        </div>
        {jsonData.line_items["mapped"]?.length > 0 && (
          <>
            <div className="mt-5 mb-5" style={{ display: "flex",gap:"1px" }}>
         
          <div style={{ flex: "1", maxWidth: "50%"}}>
            {Object.entries(jsonData)
              .filter(
                ([key]) =>
                  !["success", "line_items", "message", "extracted_line_items_total", "amounts_matched"].includes(key)
              )
              .map(([key, value]) => (
                <div key={key} style={{ marginBottom: "8px",width:"auto"}}>
                  <strong
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleFieldEdit(key, value)}
                  >
                    {key.replace(/_/g, " ").toUpperCase()}:
                  </strong>{" "}
                  {editFieldKey === key ? (
                    <input
                      type="text"
                      value={editFieldValue}
                      onChange={(e) => handleFieldChange(e)}
                      onBlur={() => saveFieldEdit(key)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={() => handleFieldEdit(key, value)}
                      style={{
                        cursor: isEditingEnabled
                          ? "pointer"
                          : "not-allowed",
                        textDecoration:
                          value === "" || value === null
                            ? "underline"
                            : "none",
                        outline:
                          editFieldKey === key
                            ? "2px solid #1E201E"
                            : "none",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.outline = isEditingEnabled
                          ? "2px solid #1E201E"
                          : "none")
                      }
                      onMouseOut={(e) => (e.target.style.outline = "none")}
                    >
                      {value === "" || value === null
                        ? "<Click to add value>"
                        : value}
                    </span>
                  )}
                </div>
              ))}
          </div>

          <div
            style={{
              flex: "0.5",
            }}
          >
            {Object.entries(jsonData)
              .filter(
                ([key]) =>
                  ["extracted_line_items_total", "amounts_matched"].includes(key)
              )
              .map(([key, value]) => (
                <div key={key} style={{ marginBottom: "8px" }}>
                  <strong
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleFieldEdit(key, value)}
                  >
                    {key.replace(/_/g, " ").toUpperCase()}:
                  </strong>{" "}
                  {editFieldKey === key ? (
                    <input
                      type="text"
                      value={editFieldValue}
                      onChange={(e) => handleFieldChange(e)}
                      onBlur={() => saveFieldEdit(key)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={() => handleFieldEdit(key, value)}
                      style={{
                        cursor: isEditingEnabled
                          ? "pointer"
                          : "not-allowed",
                        textDecoration:
                          value === "" || value === null
                            ? "underline"
                            : "none",
                        outline:
                          editFieldKey === key
                            ? "2px solid #1E201E"
                            : "none",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.outline = isEditingEnabled
                          ? "2px solid #1E201E"
                          : "none")
                      }
                      onMouseOut={(e) => (e.target.style.outline = "none")}
                    >
                      {value === "" || value === null
                        ? "<Click to add value>"
                        : value}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
          </>
        )}

        <div className="flex justify-start mb-10 mt-5">
          <Button
            onClick={addRow}
            className="mr-2"
            disabled={!isEditingEnabled || activeTab === "raw"}
          >
            Add Row
          </Button>
          <Button
            onClick={deleteSelectedRows}
            disabled={!isEditingEnabled || activeTab === "raw"}
          >
            Delete Selected Rows
          </Button>
        </div>

        <Tabs value={activeTab} key={activeTab}>
          <TabsHeader>
            <Tab value="raw" onClick={() => setActiveTab("raw")}>
              Extracted Data
            </Tab>
            <Tab value="mapped" onClick={() => setActiveTab("mapped")}>
              Zoho Data
            </Tab>
          </TabsHeader>

          {/* TabsBody renders the content based on the active tab */}
          <TabsBody>
            <TabPanel value="mapped">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Invoice Data - Zoho Data
                </Typography>
                <RenderTable
                  activeTab={activeTab}
                  selectedRows={selectedRows}
                  toggleRowSelection={toggleRowSelection}
                  data={jsonData.line_items["mapped"]}
                  isEditable={true}
                  isEditingEnabled={isEditingEnabled}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  handleInputChange={handleInputChange}
                  formatBatchNumber={formatBatchNumber}
                />
              </CardBody>
            </TabPanel>

            <TabPanel value="raw">
              <CardBody>
                <Typography variant="h6" color="black" className=" mb-4">
                  Invoice Data - Extracted Data
                </Typography>
                <RenderTable
                  activeTab={activeTab}
                  selectedRows={selectedRows}
                  toggleRowSelection={toggleRowSelection}
                  data={jsonData.line_items["raw"]}
                  isEditable={false}
                  isEditingEnabled={isEditingEnabled}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  handleInputChange={handleInputChange}
                  formatBatchNumber={formatBatchNumber}
                />
              </CardBody>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </CardBody>
    </>
  );
};

const TableCell = ({ field, item, editIndex, rowIndex, colIndex, isEditingEnabled, isEditable, handleInputChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);

  const handleViewDetails = (batches) => {
    setSelectedBatches(batches);
    setIsModalOpen(true);
  };

  // Modified batch number handling to allow editing of single batch numbers
  if (field === 'batch_number' || field === 'Batch Number') {
    // For array of batch numbers, show view button
    if (Array.isArray(item[field])) {
      return (
        <>
          {formatBatchNumber(item[field], handleViewDetails)}
          <BatchDetailsModal 
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            batchDetails={selectedBatches}
          />
        </>
      );
    }
    
    // For single batch numbers, allow editing
    return (
      <>
        {editIndex.row === rowIndex &&
        editIndex.col === colIndex &&
        isEditingEnabled &&
        isEditable ? (
          <input
            type="text"
            value={item[field] || ""}
            onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
            className="border-2 border-gray-200 p-1 w-full"
            style={{
              minWidth: '100px',
              maxWidth: '100%',
            }}
            autoFocus
          />
        ) : (
          <div className="batch-number-container">
            <div 
              className="batch-number-item"
              style={{
                cursor: isEditingEnabled ? "pointer" : "not-allowed",
              }}
            >
              {item[field] || "-"}
            </div>
          </div>
        )}
      </>
    );
  }

  // For all other fields, keep existing editing behavior
  return (
    <>
      {editIndex.row === rowIndex &&
      editIndex.col === colIndex &&
      isEditingEnabled &&
      isEditable ? (
        <input
          type="text"
          value={item[field] || ""}
          onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
          className="border-2 border-gray-200 p-1 w-full"
          style={{
            minWidth: '100px',
            maxWidth: '100%',
          }}
          autoFocus
        />
      ) : (
        <div 
          className="cell-content"
          style={{
            cursor: isEditingEnabled ? "pointer" : "not-allowed",
            padding: "4px",
            whiteSpace: "normal",
            wordBreak: "break-word"
          }}
        >
          {item[field] || "-"}
        </div>
      )}
    </>
  );
};

const RenderTable = ({
  activeTab,
  selectedRows,
  toggleRowSelection,
  data,
  isEditable = false,
  isEditingEnabled,
  setEditIndex,
  editIndex,
  handleInputChange,
  formatBatchNumber,
}) => {
  useEffect(() => {
    // Add styles to document
    const styleSheet = document.createElement("style");
    styleSheet.innerText = batchNumberStyles;
    document.head.appendChild(styleSheet);
    
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <div className="p-4 text-center text-gray-500">
        No data available
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="overflow-x-auto overflow-y-auto max-h-96">
        <table className="min-w-full bg-white border table-fixed">
          <thead className="sticky top-0 z-10">
            <tr>
              {activeTab === "mapped" && (
                <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left bg-white shadow-sm">
                  Select
                </th>
              )}
              {Object.keys(data[0])?.map((header) => (
                <th
                  key={header}
                  className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left bg-white shadow-sm"
                  style={{
                    width: header === 'product_name' ? '30%' : 
                           header === 'batch_number' ? '200px' : 'auto',
                    minWidth: '120px',
                    backgroundColor: '#f8fafc', // Light gray background
                    position: 'relative',
                    zIndex: 10
                  }}
                >
                  {header.replace(/_/g, " ").toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {activeTab === "mapped" && (
                  <td className="py-2 px-4 border-b border-gray-200 text-center w-16">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(rowIndex)}
                      onChange={() => toggleRowSelection(rowIndex)}
                      disabled={!isEditingEnabled}
                    />
                  </td>
                )}
                {Object.keys(item)?.map((field, colIndex) => (
                  <td
                    key={field}
                    className={`py-2 px-4 border-b border-gray-200 ${
                      field === 'batch_number' ? 'batch-number-cell' : ''
                    }`}
                    onClick={(e) => {
                      // Only prevent editing for array of batch numbers
                      if ((field === 'batch_number' || field === 'Batch Number') && 
                          Array.isArray(item[field])) return;
                      
                      if (isEditingEnabled && isEditable) {
                        setEditIndex({ row: rowIndex, col: colIndex });
                        e.currentTarget.style.outline = "none";
                      }
                    }}
                    style={{
                      cursor: isEditingEnabled && (!Array.isArray(item[field]) || field !== 'batch_number') 
                        ? "pointer" 
                        : "not-allowed",
                      outline: "none",
                      width: field === 'product_name' ? '30%' : 
                             field === 'batch_number' ? '200px' : 'auto',
                      minWidth: '120px',
                      position: 'relative'
                    }}
                    onMouseEnter={(e) => {
                      if ((field === 'batch_number' || field === 'Batch Number') && 
                          Array.isArray(item[field])) return;
                      if (!(editIndex.row === rowIndex && editIndex.col === colIndex)) {
                        e.currentTarget.style.outline = isEditingEnabled ? "2px solid #1E201E" : "none";
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (!(editIndex.row === rowIndex && editIndex.col === colIndex)) {
                        e.currentTarget.style.outline = "none";
                      }
                    }}
                  >
                    <TableCell
                      field={field}
                      item={item}
                      editIndex={editIndex}
                      rowIndex={rowIndex}
                      colIndex={colIndex}
                      isEditingEnabled={isEditingEnabled}
                      isEditable={isEditable}
                      handleInputChange={handleInputChange}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ErrorBoundary>
  );
};

